.breadcrumbs-container {
  min-height: var(--breadcrumb-height);
  background-color: var(--bradcrumbBackgroundColor);
  border-bottom: 1px solid var(--borders-dividers-01);
}

.-breadcrumb {
  list-style-type: none;
  font-size: 14px;
  margin: auto 0;
  height: var(--breadcrumb-height);

  & > .breadcrumb-container > .breadcrumb-item_active {
    color: var(--error600) !important;
  }

  div {
    cursor: pointer;
    display: inline-block;
    &.active {
      color: var(--breadcrumbFontColor);
    }
    font-weight: bold;
    text-transform: capitalize;

    &:not(:first-child) {
      &::before {
        font-weight: 400;
        color: rgb(51, 51, 51);
        content: ">";
        margin: 0 10px;
      }
    }
  }
}

//tag-ds breadcrumb
.tag-ds.breadcrumb-container {
  .breadcrumb-item_active {
    color: var(--text-accent-on-light);
    text-transform: none !important;
  }

  & ~ .recruitment-connect-main-view {
    #features-configuration .features-container {
      height: 100% !important;
    }
  }
}
